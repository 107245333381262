import {
  SIFAKA_ANIMATION_FAIL,
  SIFAKA_ANIMATION_REQUEST,
  SIFAKA_ANIMATION_SUCCESS,
  SIFAKA_CREATE_ANIMATION_FAIL,
  SIFAKA_CREATE_ANIMATION_REQUEST,
  SIFAKA_CREATE_ANIMATION_RESET,
  SIFAKA_CREATE_ANIMATION_SUCCESS,
  SIFAKA_DELETE_ANIMATION_FAIL,
  SIFAKA_DELETE_ANIMATION_REQUEST,
  SIFAKA_DELETE_ANIMATION_SUCCESS,
  SIFAKA_EDIT_ANIMATION_FAIL,
  SIFAKA_EDIT_ANIMATION_REQUEST,
  SIFAKA_EDIT_ANIMATION_SUCCESS,
  SIFAKA_UPDATE_ANIMATION_FAIL,
  SIFAKA_UPDATE_ANIMATION_REQUEST,
  SIFAKA_UPDATE_ANIMATION_RESET,
  SIFAKA_UPDATE_ANIMATION_SUCCESS,
} from "../Constants/AnimationSifakaConstants";

//Get Animation
export const animationSifakaListReducer = (
  state = { animationSifaka: [] },
  action
) => {
  switch (action.type) {
    case SIFAKA_ANIMATION_REQUEST:
      return { loading: true, animationSifaka: [] };
    case SIFAKA_ANIMATION_SUCCESS:
      return { loading: false, animationSifaka: action.payload };
    case SIFAKA_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE Animation
export const animationSifakaCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SIFAKA_CREATE_ANIMATION_REQUEST:
      return { loading: true };
    case SIFAKA_CREATE_ANIMATION_SUCCESS:
      return {
        loading: false,
        success: true,
        animationSifaka: action.payload,
      };
    case SIFAKA_CREATE_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    case SIFAKA_CREATE_ANIMATION_RESET:
      return {};
    default:
      return state;
  }
};

// EDIT Animation
export const animationSifakaEditReducer = (
  state = { animationSifaka: {} },
  action
) => {
  switch (action.type) {
    case SIFAKA_EDIT_ANIMATION_REQUEST:
      return { ...state, loading: true };
    case SIFAKA_EDIT_ANIMATION_SUCCESS:
      return {
        loading: false,
        success: true,
        animationSifaka: action.payload,
      };
    case SIFAKA_EDIT_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DELETE Animation
export const animationSifakaDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SIFAKA_DELETE_ANIMATION_REQUEST:
      return { loading: true };
    case SIFAKA_DELETE_ANIMATION_SUCCESS:
      return { loading: false, success: true };
    case SIFAKA_DELETE_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPDATE ANIMATION
export const animationSifakaUpdateReducer = (
  state = { animationSifaka: {} },
  action
) => {
  switch (action.type) {
    case SIFAKA_UPDATE_ANIMATION_REQUEST:
      return { loading: true };
    case SIFAKA_UPDATE_ANIMATION_SUCCESS:
      return {
        loading: false,
        success: true,
        animationSifaka: action.payload,
      };
    case SIFAKA_UPDATE_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    case SIFAKA_UPDATE_ANIMATION_RESET:
      return { animationSifaka: {} };
    default:
      return state;
  }
};
