import React, { useEffect } from "react";
import CreateCategory from "./CreateCategory";
import CategoriesTable from "./CategoriesTable";
import { useDispatch, useSelector } from "react-redux";
import { listCategory } from "../../Redux/Actions/CategoryActions";

const MainCategories = () => {

  const dispatch = useDispatch();
  const categoryLists = useSelector((state) => state.categoryList);
  const category = categoryLists?.categories?.categoryList;

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Categories</h2>
      </div>

      <div className="card shadow-sm">
        <div className="card-body">
          <div className="row">
            {/* Create category */}
            <CreateCategory />
            {/* Categories table */}
            <CategoriesTable category={category} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainCategories;
