import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import FlashSale from "./FlashSale";
import { useDispatch, useSelector } from "react-redux";
import { listFlashSale } from "../../Redux/Actions/FlashSaleActions";

const MainFlashSale = () => {
  const dispatch = useDispatch();
  const listFlashsale = useSelector((state) => state.flashsaleList);
  const flashsales = useSelector((state) => state.flashsaleList.flashsales);
  const loading = useSelector((state) => state.flashsaleList.loading);
  const error = useSelector((state) => state.flashsaleList.error)
  // const { loading, error } = listFlashsale || {};

  const deleteFlashSale = useSelector((state) => state.deleteFlashSale);
  const { error: errorDelete, success: successDelete } = deleteFlashSale || {};

  useEffect(() => {
    dispatch(listFlashSale());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listFlashSale());
  }, [dispatch, successDelete]);

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Vente flash</h2>
        <div>
          <Link to="/ajout-vente-flash" className="btn btn-primary">
            Ajouter vente flash
          </Link>
        </div>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white ">
          <div className="row gx-3 py-3">
            <div className="col-lg-4 col-md-6 me-auto ">
              <input
                type="search"
                placeholder="Recherche"
                className="form-control p-2"
              />
            </div>
            {/* <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Tous les categories</option>
                <option>Electronics</option>
                <option>Clothings</option>
                <option>Something else</option>
              </select>
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select className="form-select">
                <option>Dernière ajout</option>
                <option>Cheap first</option>
                <option>Most viewed</option>
              </select>
            </div> */}
          </div>
        </header>

        <div className="card-body">
          {errorDelete && (
            <Message variant="alert-danger">{errorDelete}</Message>
          )}
          {loading ? (
            <Loading />
          ) : (
            error ? (
              <Message variant="alert-danger">{error}</Message>
            ) : (
              <div className="row">
                {/* Products */}
                {flashsales &&
                  flashsales.map((flashsale) => (
                    <FlashSale flashsale={flashsale} key={flashsale?._id} />
                  ))}
              </div>
            )
          )
          }

          <nav className="float-end mt-4" aria-label="Page navigation">
            <ul className="pagination">
              <li className="page-item disabled">
                <Link className="page-link" to="#">
                  Précédent
                </Link>
              </li>
              <li className="page-item active">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  Suivant
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default MainFlashSale;
