import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCategory,
  listCategory,
} from "../../Redux/Actions/CategoryActions";

const CategoriesTable = (props) => {
  // const { category } = props;
  const dispatch = useDispatch();
  const categoryLists = useSelector((state) => state.categoryList);
  const categorys = categoryLists?.categories?.categoryList;

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Vous voulez le supprimer?")) {
      dispatch(deleteCategory(id));
    }
  };

  return (
    <div className="col-md-12 col-lg-8">
      <table className="table">
        <thead>
          <tr>
            <th>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" />
              </div>
            </th>
            <th>Catégorie</th>
            <th>Code</th>
            <th>Sous-catégorie</th>
            <th className="text-end">Action</th>
          </tr>
        </thead>
        {/* Table Data */}
        <tbody>
          {categorys?.map((cat, index) => (
            <tr key={index}>
              <td>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                  />
                </div>
              </td>
              <td>
                <b>{cat?.name}</b>
              </td>
              <td>{cat?.slug}</td>
              <td>
                {cat.children?.map((sc, index) => (
                  <ul key={index}>
                    <li>{sc?.name}</li>
                  </ul>
                ))}
              </td>
              <td className="text-end">
                <div className="dropdown">
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    className="btn btn-light"
                  >
                    <i className="fas fa-ellipsis-h"></i>
                  </Link>
                  <div className="dropdown-menu">
                    {/* <Link className="dropdown-item" to="#">
                      Edit info
                    </Link> */}
                    <Link
                      className="dropdown-item text-danger"
                      to="#"
                      onClick={() => handleDelete(cat?._id)}
                    >
                      Supprimer
                    </Link>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoriesTable;
