import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AnimationVenteFlash } from "./AnimationVenteFlash";
import { listAnimationFlashSale } from "./../../Redux/Actions/AnimationFlashSaleActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

const AnimationMain = () => {
  const dispatch = useDispatch();
  const animationListFlashSale = useSelector(
    (state) => state.flashsaleAnimationList
  );
  const { loading, error, animationFlashSale } = animationListFlashSale || {};

  useEffect(() => {
    dispatch(listAnimationFlashSale());
  }, [dispatch]);

  // console.log("animationFlashSale", animationFlashSale);

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Animation Vente flash</h2>
        <div>
          <Link to="/ajout-animation-vente-flash" className="btn btn-primary">
            Ajouter animation vente flash
          </Link>
        </div>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white ">
          <div className="row gx-3 py-3">
            {/* <div className="col-lg-4 col-md-6 me-auto ">
              <input
                type="search"
                placeholder="Recherche"
                className="form-control p-2"
              />
            </div> */}
          </div>
        </header>

        <div className="card-body">
          {/* <AnimationVenteFlash /> */}
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <div className="row">
              {animationFlashSale &&
                animationFlashSale?.map((animation) => (
                  <AnimationVenteFlash
                    animation={animation}
                    key={animation?._id}
                  />
                ))}
            </div>
          )}

          {/* <nav className="float-end mt-4" aria-label="Page navigation">
            <ul className="pagination">
              <li className="page-item disabled">
                <Link className="page-link" to="#">
                  Précédent
                </Link>
              </li>
              <li className="page-item active">
                <Link className="page-link" to="#">
                  1
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  2
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  3
                </Link>
              </li>
              <li className="page-item">
                <Link className="page-link" to="#">
                  Suivant
                </Link>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </section>
  );
};

export default AnimationMain;
