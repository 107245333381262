export const FLASHSALE_LIST_REQUEST = "FLASHSALE_LIST_REQUEST";
export const FLASHSALE_LIST_SUCCESS = "FLASHSALE_LIST_SUCCESS";
export const FLASHSALE_LIST_FAIL = "FLASHSALE_LIST_FAIL";

export const FLASHSALE_DELETE_REQUEST = "FLASHSALE_DELETE_REQUEST";
export const FLASHSALE_DELETE_SUCCESS = "FLASHSALE_DELETE_SUCCESS";
export const FLASHSALE_DELETE_FAIL = "FLASHSALE_DELETE_FAIL";

export const FLASHSALE_EDIT_REQUEST = "FLASHSALE_EDIT_REQUEST";
export const FLASHSALE_EDIT_SUCCESS = "FLASHSALE_EDIT_SUCCESS";
export const FLASHSALE_EDIT_FAIL = "FLASHSALE_EDIT_FAIL";

export const FLASHSALE_CREATE_REQUEST = "FLASHSALE_CREATE_REQUEST";
export const FLASHSALE_CREATE_SUCCESS = "FLASHSALE_CREATE_SUCCESS";
export const FLASHSALE_CREATE_FAIL = "FLASHSALE_CREATE_FAIL";
export const FLASHSALE_CREATE_RESET = "FLASHSALE_CREATE_RESET";

export const FLASHSALE_UPDATE_REQUEST = "FLASHSALE_UPDATE_REQUEST";
export const FLASHSALE_UPDATE_SUCCESS = "FLASHSALE_UPDATE_SUCCESS";
export const FLASHSALE_UPDATE_FAIL = "FLASHSALE_UPDATE_FAIL";
export const FLASHSALE_UPDATE_RESET = "FLASHSALE_UPDATE_RESET";
