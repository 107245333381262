import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  editFlashSaleAnimation,
  updateFlashSaleAnimation,
} from "../../Redux/Actions/AnimationFlashSaleActions";
import { FLASHSALE_UPDATE_ANIMATION_RESET } from "../../Redux/Constants/AnimationFlashSaleConstants";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

export const EditAnimationFlashSale = (props) => {
  const { animationFlashSaleId } = props;

  // console.log("animationFlashSaleId", animationFlashSaleId);

  const [animation, setAnimation] = useState("");

  const dispatch = useDispatch();
  const flashSaleEditAnimation = useSelector(
    (state) => state.flashSaleAnimationEdit
  );
  const { loading, error, animationFlashSale } = flashSaleEditAnimation || {};

  const flashsaleUpdate = useSelector(
    (state) => state.flashSaleAnimationUpdate
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = flashsaleUpdate || {};

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: FLASHSALE_UPDATE_ANIMATION_RESET });
      toast.success("Animation vente flash à jour", ToastObjects);
    } else {
      if (
        !animationFlashSale?.animation ||
        animationFlashSale?._id !== animationFlashSaleId
      ) {
        dispatch(editFlashSaleAnimation(animationFlashSaleId));
      } else {
        setAnimation(animationFlashSale?.animation);
      }
    }
  }, [dispatch, animationFlashSale, animationFlashSaleId, successUpdate]);

  const handleUpdateFlashsaleAnimation = (event) => {
    event.preventDefault();
    dispatch(
      updateFlashSaleAnimation({
        _id: animationFlashSaleId,
        animation,
      })
    );
  };

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={handleUpdateFlashsaleAnimation}>
          <div className="content-header">
            <Link
              to="/animation-vente-flash"
              className="btn btn-return text-white"
            >
              Aller à l'animation vente flash
            </Link>
            <h2 className="content-title">Mis à jour animation vente flash</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publier
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {errorUpdate && (
                    <Message variant="alert-danger">{errorUpdate}</Message>
                  )}
                  {loadingUpdate && <Loading />}
                  {loading ? (
                    <Loading />
                  ) : error ? (
                    <Message variant="alert-danger">{error}</Message>
                  ) : (
                    <div className="mb-4">
                      <label
                        htmlFor="animation-flashsale_title"
                        className="form-label"
                      >
                        Modifier animation
                      </label>
                      <input
                        type="text"
                        placeholder="Animation"
                        className="form-control"
                        id="animation-flashsale_title"
                        required
                        value={animation}
                        onChange={(event) => setAnimation(event.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};
