import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createCategory,
  listCategory,
} from "../../Redux/Actions/CategoryActions";
import { CATEGORY_CREATE_RESET } from "../../Redux/Constants/CategoryConstants";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

export const CreateSubCategory = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  // const [slug, setSlug] = useState("");
  const [parentId, setParentId] = useState("");

  const categoryCreate = useSelector((state) => state.categoryCreate);
  const { loading, error, category } = categoryCreate || {};

  const categoryLists = useSelector((state) => state.categoryList);
  const categorys = categoryLists?.categories?.categoryList;

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  useEffect(() => {
    if (category) {
      toast.success("Categorie ajouté", ToastObjects);
      dispatch({ type: CATEGORY_CREATE_RESET });
      setName("");
      setParentId("");
    }
  }, [category, dispatch]);

  // console.log("CAT*****", categorys);

  const handleAddCategory = (event) => {
    event.preventDefault();
    dispatch(createCategory(name, undefined, parentId));
    setName("");
    setParentId("");
  };

  return (
    <form onSubmit={handleAddCategory}>
      {error && <Message variant="alert-danger">{error}</Message>}
      {loading && <Loading />}
      <div className="mb-4">
        <label htmlFor="category_name" className="form-label">
          Nom :
        </label>
        <input
          type="text"
          placeholder="Nom"
          className="form-control py-3"
          id="category_name"
          required
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </div>
      <div className="flex-box d-flex justify-content-between align-items-center">
        <h6>Categorie</h6>
        <select
          value={parentId}
          onChange={(event) => setParentId(event.target.value)}
        >
          <option>Choisir sous-categorie</option>
          {categorys?.map((cat) => (
            <option key={cat._id} value={cat._id}>
              {cat?.name}
            </option>
          ))}
        </select>
      </div>
      <div className="d-grid">
        <button className="btn btn-primary py-3">Créer sous-categorie</button>
      </div>
    </form>
  );
};
