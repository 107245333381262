import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteFlashSaleAnimation } from "../../Redux/Actions/AnimationFlashSaleActions";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    boxAction: {
      display: "flex",
    },
  })
);

export const AnimationVenteFlash = (props) => {
  const classes = useStyles();
  const { animation } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = (id) => {
    if (window.confirm("Vous voulez le supprimer?")) {
      dispatch(deleteFlashSaleAnimation(id));
    }
  };

  const handleEdit = () => {
    history.push(`/animation-venteflash/${animation?._id}/edit`);
  };

  // console.log("animationFlashSale-test", animation);

  return (
    <>
      <Box className={classes.root}>
        <Typography>{animation?.animation}</Typography>
        <Box className={classes.boxAction}>
          <IconButton onClick={handleEdit}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => handleDelete(animation?._id)}>
            <Delete />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
