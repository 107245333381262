import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const Orders = (props) => {
  const { orders } = props;

  // console.log("Commande=========", orders);
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col">Email</th>
          <th scope="col">Total</th>
          <th scope="col">Payé</th>
          <th scope="col">Date</th>
          <th>Statut</th>
          <th scope="col" className="text-end">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((order) => (
          <tr key={order?._id}>
            <td>
              <b>{order?.user?.name}</b>
            </td>
            <td>{order?.user?.email}</td>
            <td>{order?.totalPrice} Ar</td>
            <td>
              {order?.isPaid ? (
                <span className="badge rounded-pill alert-success">
                  Payé le {moment(order?.paidAt).format("MMM Do YY")}
                </span>
              ) : (
                <span className="badge rounded-pill alert-danger">
                  Non payé
                </span>
              )}
            </td>
            <td>{moment(order?.createdAt).format("MMM Do YY")}</td>
            <td>
              {order?.isDelivered ? (
                <span className="badge btn-success">livré</span>
              ) : (
                <span className="badge btn-dark">Non livré</span>
              )}
            </td>
            <td className="d-flex justify-content-end align-item-center">
              <Link to={`/commande/${order?._id}`} className="text-success">
                <i className="fas fa-eye"></i>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Orders;
