import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userListReducer, userLoginReducer } from "./Reducers/UserReducers";
import {
  productCreateReducer,
  productDeleteReducer,
  productEditReducer,
  productListReducer,
  productUpdateReducer,
} from "./Reducers/ProductReducers";
import {
  orderDetailsReducer,
  orderListReducer,
} from "./Reducers/OrderReducers";
import {
  flashSaleDeleteReducer,
  flashSaleEditReducer,
  flashSaleListReducer,
  flashSlaeUpdateReducer,
} from "./Reducers/FlashSaleReducers";
import {
  categoryDeleteReducer,
  categoryEditReducer,
  categoryListReducer,
} from "./Reducers/CategoryReducers";
import {
  imageCreateReducer,
  imageDeleteReducer,
  imageEditReducer,
  imageListReducer,
} from "./Reducers/ImageReducers";
import {
  animatioFlashSaleCreateReducer,
  animationFlashSaleDeleteReducer,
  animationFlashSaleEditReducer,
  animationFlashSaleListReducer,
  flashSaleUpdateAnimationReducer,
} from "./Reducers/AnimationFlashSaleReducers";
import {
  animationSifakaCreateReducer,
  animationSifakaDeleteReducer,
  animationSifakaEditReducer,
  animationSifakaListReducer,
  animationSifakaUpdateReducer,
} from "./Reducers/AnimationSifakaReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userList: userListReducer,
  productList: productListReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productEdit: productEditReducer,
  productUpdate: productUpdateReducer,
  orderList: orderListReducer,
  orderDetails: orderDetailsReducer,
  flashsaleList: flashSaleListReducer,
  flashsaleEdit: flashSaleEditReducer,
  flashsaleUpdate: flashSlaeUpdateReducer,
  flashsaleDelete: flashSaleDeleteReducer,
  categoryList: categoryListReducer,
  categoryDelete: categoryDeleteReducer,
  categoryEdit: categoryEditReducer,
  imageList: imageListReducer,
  imageCreate: imageCreateReducer,
  imageDelete: imageDeleteReducer,
  imageEdit: imageEditReducer,
  flashsaleAnimationList: animationFlashSaleListReducer,
  flashSaleAnimationCreate: animatioFlashSaleCreateReducer,
  flashSaleAnimationEdit: animationFlashSaleEditReducer,
  flashSaleAnimationDelete: animationFlashSaleDeleteReducer,
  flashSaleAnimationUpdate: flashSaleUpdateAnimationReducer,
  sifakaAnimationList: animationSifakaListReducer,
  sifakaAnimationCreate: animationSifakaCreateReducer,
  sifakaAnimationEdit: animationSifakaEditReducer,
  sifakaAnimationDelete: animationSifakaDeleteReducer,
  sifakaAnimationUpdate: animationSifakaUpdateReducer,
});

// LOGIN
const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: {
    userInfo: userInfoFromLocalStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
