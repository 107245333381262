import React, { useEffect } from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import MainProducts from "./../components/products/MainProducts";
import { listProducts } from "../Redux/Actions/ProductActions";
import { useDispatch } from "react-redux";

const ProductScreen = ({ match }) => {
  // console.log('***************',match)
  const dispatch = useDispatch();
  const searchText = match.params.searchText;
  const pageNumber = match.params.pageNumber;
  const parentId = match.params.parentId;

  useEffect(() => {
    dispatch(listProducts(searchText, pageNumber, parentId));
  }, [dispatch, searchText, pageNumber, parentId]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <MainProducts
          searchText={searchText}
          pageNumber={pageNumber}
          parentId={parentId}
        />
      </main>
    </>
  );
};

export default ProductScreen;
