import { toast } from "react-toastify";
import {
  FLASHSALE_CREATE_FAIL,
  FLASHSALE_CREATE_REQUEST,
  FLASHSALE_CREATE_RESET,
  FLASHSALE_CREATE_SUCCESS,
  FLASHSALE_DELETE_FAIL,
  FLASHSALE_DELETE_REQUEST,
  FLASHSALE_DELETE_SUCCESS,
  FLASHSALE_EDIT_FAIL,
  FLASHSALE_EDIT_REQUEST,
  FLASHSALE_EDIT_SUCCESS,
  FLASHSALE_LIST_FAIL,
  FLASHSALE_LIST_REQUEST,
  FLASHSALE_LIST_SUCCESS,
  FLASHSALE_UPDATE_FAIL,
  FLASHSALE_UPDATE_REQUEST,
  FLASHSALE_UPDATE_RESET,
  FLASHSALE_UPDATE_SUCCESS,
} from "../Constants/FlashSaleConstants";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

// ALL FLASH SALE
export const flashSaleListReducer = (state = { flashsales: [] }, action) => {
  switch (action.type) {
    case FLASHSALE_LIST_REQUEST:
      return { loading: true, flashsales: [] };
    case FLASHSALE_LIST_SUCCESS:
      return { loading: false, flashsales: action.payload };
    case FLASHSALE_LIST_FAIL:
      return { loading: false, error: action.payload };

    // CREATE REDUCER
    case FLASHSALE_CREATE_REQUEST:
      return { loading: true };
    case FLASHSALE_CREATE_SUCCESS:
      // console.log('******************************************')
      toast.success("Vente flash ajouté", ToastObjects);
      return { loading: false, success: true, flashsale: [...(state?.flashsales || []), action.payload] };
    case FLASHSALE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case FLASHSALE_CREATE_RESET:
      return {};

    // DELETE
    case FLASHSALE_DELETE_REQUEST:
      return { loading: true };
    case FLASHSALE_DELETE_SUCCESS:
      // console.log(state, action.payload)
      const newFlashSale = state?.flashsales?.filter((flashsale) => {
        return flashsale._id !== action.payload._id
      })
      console.log(typeof newFlashSale, newFlashSale || [])
      return { loading: false, success: true, flashsales: newFlashSale || [] };
    case FLASHSALE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DELETE
export const flashSaleDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    // case FLASHSALE_DELETE_REQUEST:
    //   return { loading: true };
    // case FLASHSALE_DELETE_SUCCESS:
    //   console.log(state, action.payload)
    //   const newFlashSale = state?.flashsales?.filter((flashsale) => {
    //     console.log(flashsale.id, action.payload.id)
    //     return flashsale.id !== action.payload.id
    //   })
    //   console.log(typeof newFlashSale, newFlashSale || [])
    //   return { loading: false, success: true, flashsales: newFlashSale || [] };
    // case FLASHSALE_DELETE_FAIL:
    //   return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE
export const flashSaleCreateReducer = (state = {}, action) => {
  switch (action.type) {
    // case FLASHSALE_CREATE_REQUEST:
    //   return { loading: true };
    // case FLASHSALE_CREATE_SUCCESS:
    //   return { loading: false, success: true, flashsale: action.payload };
    // case FLASHSALE_CREATE_FAIL:
    //   return { loading: false, error: action.payload };
    // case FLASHSALE_CREATE_RESET:
    //   return {};
    default:
      return state;
  }
};

// EDIT
export const flashSaleEditReducer = (state = { flashsale: {} }, action) => {
  switch (action.type) {
    case FLASHSALE_EDIT_REQUEST:
      return { ...state, loading: true };
    case FLASHSALE_EDIT_SUCCESS:
      return { loading: false, success: true, flashsale: action.payload };
    case FLASHSALE_EDIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPDATE
export const flashSlaeUpdateReducer = (state = { flashsale: {} }, action) => {
  switch (action.type) {
    case FLASHSALE_UPDATE_REQUEST:
      return { loading: true };
    case FLASHSALE_UPDATE_SUCCESS:
      return { loading: false, success: true, flashsale: action.payload };
    case FLASHSALE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case FLASHSALE_UPDATE_RESET:
      return { flashsale: {} };
    default:
      return state;
  }
};
