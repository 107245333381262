import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../Redux/Actions/ProductActions";

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      padding: 8,
      marginLeft: 4,
      fontSize: 14,
      fontFamily: "Roboto",
      fontWeight: 600,
      color: "#212121",
    },
  })
);

const Product = (props) => {
  const classes = useStyle();
  const { product } = props;

  const dispatch = useDispatch();

  const handleDelete = (id) => {
    if (window.confirm("Vous voulez le supprimer?")) {
      dispatch(deleteProduct(id));
    }
  };

  return (
    <>
      <div className="col-md-6 col-sm-6 col-lg-3 mb-5">
        <div className="card card-product-grid shadow-sm">
          {product?.ref ? (
            <Typography classes={{ root: classes.root }}>
              Ref : {product.ref}
            </Typography>
          ) : null}
          <Link to="#" className="img-wrap">
            <img src={product?.image} alt="Product" />
          </Link>
          <div className="info-wrap">
            <Link to="#" className="title text-truncate">
              {product?.name}
            </Link>
            <div className="price mb-2">{product?.price} Ar</div>
            <div className="row-btn">
              <Link
                to={`/product/${product?._id}/edit`}
                className="btn btn-sm btn-action btn-outline-success p-2 pb-3 col-md-6"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <Link
                to="#"
                onClick={() => handleDelete(product?._id)}
                className="btn btn-sm btn-action btn-outline-danger p-2 pb-3 col-md-6"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
