export const FLASHSALE_ANIMATION_REQUEST = "FLASHSALE_ANIMATION_REQUEST";
export const FLASHSALE_ANIMATION_SUCCESS = "FLASHSALE_ANIMATION_SUCCESS";
export const FLASHSALE_ANIMATION_FAIL = "FLASHSALE_ANIMATION_FAIL";

export const FLASHSALE_CREATE_ANIMATION_REQUEST =
  "FLASHSALE_CREATE_ANIMATION_REQUEST";
export const FLASHSALE_CREATE_ANIMATION_SUCCESS =
  "FLASHSALE_CREATE_ANIMATION_SUCCESS";
export const FLASHSALE_CREATE_ANIMATION_FAIL =
  "FLASHSALE_CREATE_ANIMATION_FAIL";
export const FLASHSALE_CREATE_ANIMATION_RESET =
  "FLASHSALE_CREATE_ANIMATION_RESET";

export const FLASHSALE_EDIT_ANIMATION_REQUEST =
  "FLASHSALE_EDIT_ANIMATION_REQUEST";
export const FLASHSALE_EDIT_ANIMATION_SUCCESS =
  "FLASHSALE_EDIT_ANIMATION_SUCCESS";
export const FLASHSALE_EDIT_ANIMATION_FAIL = "FLASHSALE_EDIT_ANIMATION_FAIL";

export const FLASHSALE_DELETE_ANIMATION_REQUEST =
  "FLASHSALE_DELETE_ANIMATION_REQUEST";
export const FLASHSALE_DELETE_ANIMATION_SUCCESS =
  "FLASHSALE_DELETE_ANIMATION_SUCCESS";
export const FLASHSALE_DELETE_ANIMATION_FAIL =
  "FLASHSALE_DELETE_ANIMATION_FAIL";

export const FLASHSALE_UPDATE_ANIMATION_REQUEST =
  "FLASHSALE_UPDATE_ANIMATION_REQUEST";
export const FLASHSALE_UPDATE_ANIMATION_SUCCESS =
  "FLASHSALE_UPDATE_ANIMATION_SUCCESS";
export const FLASHSALE_UPDATE_ANIMATION_FAIL =
  "FLASHSALE_UFLASHSALE_UPDATE_ANIMATION_FAILPDATE_FAIL";
export const FLASHSALE_UPDATE_ANIMATION_RESET =
  "FLASHSALE_UPDATE_ANIMATION_RESET";
