import axios from "axios";
import {
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
} from "../Constants/OrderConstants";
import { logout } from "./UserActions";

export const listOrders = () => async (dispatch, getState) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: ORDER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Credentials": "true",
      },
    };
    const { data } = await axios.get(
      `${URL}/api/orders/all`,
      config
    );

    dispatch({ type: ORDER_LIST_SUCCESS, payload: data });
    // localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_LIST_FAIL,
      payload: message,
    });
  }
};

// ORDER DETAILS
export const getOrderDetails = (id) => async (dispatch, getState) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Credentials": "true",
      },
    };
    const { data } = await axios.get(
      `${URL}/api/orders/${id}`,
      config
    );
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    });
  }
};
