import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import MainFlashSale from "../components/flashSales/MainFlashSale";

const FlashSaleScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <MainFlashSale />
      </main>
    </>
  );
};

export default FlashSaleScreen;
