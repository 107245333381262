import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";
import { toast } from "react-toastify";
import { CATEGORY_CREATE_RESET } from "../../Redux/Constants/CategoryConstants";
import { createCategory } from "../../Redux/Actions/CategoryActions";
import { CreateSubCategory } from "./CreateSubCategory";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const CreateCategory = () => {
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [showSubcategory, setShowSubcategory] = useState(false);

  const dispatch = useDispatch();
  const categoryCreate = useSelector((state) => state.categoryCreate);
  const { loading, error, category } = categoryCreate || {};

  useEffect(() => {
    if (category) {
      toast.success("Categorie ajouté", ToastObjects);
      dispatch({ type: CATEGORY_CREATE_RESET });
      setName("");
      setSlug("");
    }
  }, [dispatch, category]);

  const handleAddCategory = (event) => {
    event.preventDefault();
    dispatch(createCategory(name, slug));
    setName("");
    setSlug("");
  };

  const handleShowCategory = () => {
    setShowSubcategory((prev) => !prev);
  };

  return (
    <>
      <Toast />
      <div className="col-md-12 col-lg-4">
        {showSubcategory ? (
          <h4 className="content-title">Ajouter sous-catégorie :</h4>
        ) : (
          <h4 className="content-title">Ajouter catégorie :</h4>
        )}
        {showSubcategory ? (
          <CreateSubCategory />
        ) : (
          <form onSubmit={handleAddCategory}>
            {error && <Message variant="alert-danger">{error}</Message>}
            {loading && <Loading />}
            <div className="mb-4">
              <label htmlFor="category_name" className="form-label">
                Nom :
              </label>
              <input
                type="text"
                placeholder="Nom"
                className="form-control py-3"
                id="category_name"
                required
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="slug_name" className="form-label">
                Code :
              </label>
              <input
                type="text"
                placeholder="Le code sera le même que le nom"
                className="form-control py-3"
                id="slug_name"
                required
                value={slug}
                onChange={(event) => setSlug(event.target.value)}
              />
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary py-3"
                onClick={handleAddCategory}
              >
                Créer catégorie
              </button>
            </div>
          </form>
        )}
        <div className="show-category">
          <span onClick={handleShowCategory} className="cat-text">
            {showSubcategory ? "Catégorie" : "Sous-catégorie"}
          </span>
        </div>
      </div>
    </>
  );
};

export default CreateCategory;
