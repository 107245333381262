import axios from "axios";


const axiosHttp = axios.create({
    // baseURL: process.env.REACT_APP_URI,
});

// axiosHttp.interceptors.request.use(
//     (config) => {
//         const token = "Your Token here"
//         return {
//             ...config,
//             headers: {
//                 ...(token !== null && { Authorization: `${token}` }),
//                 ...config.headers,
//             },
//         };
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

axiosHttp.interceptors.response.use(
    (response) => {
        console.log('RESPONSE', response)
        // const url = response.config.url;

        //setLocalStorageToken(token);
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            //(`unauthorized :)`);
            localStorage.clear()
            //removeLocalStorageToken
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export default axiosHttp;