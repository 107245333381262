import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import { EditAnimationFlashSale } from "../components/AnimationVenteFlash/EditAnimationFlashSale";

const EditAnimationFlashSaleScreen = ({ match }) => {
  const animationFlashSaleId = match.params.id;
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <EditAnimationFlashSale animationFlashSaleId={animationFlashSaleId} />
      </main>
    </>
  );
};
export default EditAnimationFlashSaleScreen;
