import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listAnimationSifaka } from "../../Redux/Actions/AnimationSifakaAction";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import { AnimationSifakaForm } from "./AnimationSifakaForm";

const AnimationSifakaMain = () => {
  const dispatch = useDispatch();
  const animationListSifaka = useSelector((state) => state.sifakaAnimationList);
  const { loading, error, animationSifaka } = animationListSifaka || {};

  useEffect(() => {
    dispatch(listAnimationSifaka());
  }, [dispatch]);

  // console.log("animationSifaka", animationSifaka);

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Animation Sifaka</h2>
        <div>
          <Link to="/ajout-animation-sifaka" className="btn btn-primary">
            Ajouter animation Sifaka
          </Link>
        </div>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white ">
          <div className="row gx-3 py-3">
            {/* <div className="col-lg-4 col-md-6 me-auto ">
              <input
                type="search"
                placeholder="Recherche"
                className="form-control p-2"
              />
            </div> */}
          </div>
        </header>

        <div className="card-body">
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <div className="row">
              {animationSifaka &&
                animationSifaka?.map((animation) => (
                  <AnimationSifakaForm
                    animation={animation}
                    key={animation?._id}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AnimationSifakaMain;
