import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import { EditAnimationSifaka } from "../components/AnimationSifaka/EditAnimationSifaka";

const EditAnimationSifakaScreen = ({ match }) => {
  const animationSifakaId = match?.params?.id;
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <EditAnimationSifaka animationSifakaId={animationSifakaId || null} />
      </main>
    </>
  );
};
export default EditAnimationSifakaScreen;
