import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { listCategory } from "../../Redux/Actions/CategoryActions";
import Pagination from "./pagination";

const MainProducts = (props) => {
  // const history = useHistory();
  const { pageNumber, } = props;
  const [parentId, setParentId] = useState("");
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products, pages, page } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const { error: errorDelete, success: successDelete } = productDelete;

  const categoryLists = useSelector((state) => state.categoryList);
  const category = categoryLists?.categories?.categoryList;

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listProducts(searchText, pageNumber, parentId));
  }, [dispatch, searchText, pageNumber, parentId, successDelete]);

  const listCategorys = category
    ?.map((cat) => {
      return cat?.children;
    })
    .flat();

  console.log("PAGE*****", pageNumber);
  // console.log("PRODUITS*****", products.products);

  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Produits</h2>
        <div>
          <Link to="/ajout-produit" className="btn btn-primary">
            Ajouter produit
          </Link>
        </div>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white ">
          <div className="row gx-3 py-3">
            <div className="col-lg-4 col-md-6 me-auto ">
              <input
                type="search"
                className="form-control rounded search"
                placeholder="Recherche"
                onChange={(event) => setSearchText(event.target.value)}
              />
            </div>
            <div className="box-select">
              <select
                className="form-select"
                value={parentId}
                onChange={(event) => setParentId(event.target.value)}
              >
                <option>Tous les categories</option>
                {listCategorys?.map((cat) => (
                  <option key={cat?._id}>{cat?.name}</option>
                ))}
              </select>
            </div>
          </div>
        </header>

        <div className="card-body">
          {errorDelete && (
            <Message variant="alert-danger">{errorDelete}</Message>
          )}
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <div className="row">
              {/* Products */}
              {products?.products?.map((product) => (
                <Product product={product} key={product?._id} />
              ))}
            </div>
          )}
          <Pagination
            pages={pages}
            page={page}
            searchText={searchText ? searchText : ""}
            prefix='/produits'
          />
        </div>
      </div>
    </section>
  );
};

export default MainProducts;
