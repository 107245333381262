import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteFlashSale } from "../../Redux/Actions/FlashSaleActions";

const FlashSale = (props) => {
  const { flashsale } = props;

  const dispatch = useDispatch();

  const handleDelete = (id) => {
    if (window.confirm("Vous voulez le supprimer?")) {
      dispatch(deleteFlashSale(id));
    }
  };

  return (
    <>
      <div className="col-md-6 col-sm-6 col-lg-3 mb-5">
        <div className="card card-product-grid shadow-sm">
          <Link to="#" className="img-wrap">
            <img src={flashsale?.image} alt="Product" />
          </Link>
          <div className="info-wrap">
            <Link to="#" className="title text-truncate">
              {flashsale?.name}
            </Link>
            <div className="price mb-2">{flashsale?.price} Ar</div>
            <div className="row-btn">
              <Link
                to={`/venteflash/${flashsale?._id}/edit`}
                className="btn btn-sm btn-action btn-outline-success p-2 pb-3 col-md-6"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <Link
                to="#"
                onClick={() => handleDelete(flashsale?._id)}
                className="btn btn-sm btn-action btn-outline-danger p-2 pb-3 col-md-6"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlashSale;
