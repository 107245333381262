import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  editSifakaAnimation,
  updateSifakaAnimation,
} from "../../Redux/Actions/AnimationSifakaAction";
import { SIFAKA_UPDATE_ANIMATION_RESET } from "../../Redux/Constants/AnimationSifakaConstants";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

export const EditAnimationSifaka = (props) => {
  const { animationSifakaId } = props;

  const [animation, setAnimation] = useState("");

  const dispatch = useDispatch();
  const sifakaEditAnimation = useSelector((state) => state.sifakaAnimationEdit);
  const { loading, error, animationSifaka } = sifakaEditAnimation || {};
  // console.log("animationSifakaId", animationSifakaId, animationSifaka);

  const sifakaUpdate = useSelector((state) => state.sifakaAnimationUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = sifakaUpdate || {};

  // const listAnimSifaka = animationSifaka?.find(
  //   (el) => el?.id === animationSifakaId
  // );

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: SIFAKA_UPDATE_ANIMATION_RESET });
      toast.success("Animation sifaka à jour", ToastObjects);
    } else {
      if (
        !animationSifaka?.animation ||
        animationSifaka?._id !== animationSifakaId
      ) {
        dispatch(editSifakaAnimation(animationSifakaId));
      } else {
        setAnimation(animationSifaka?.animation || "");
      }
    }
  }, [dispatch, animationSifaka, animationSifakaId, successUpdate]);

  const handleUpdateSifakaAnimation = (event) => {
    event.preventDefault();
    dispatch(
      updateSifakaAnimation({
        _id: animationSifakaId,
        animation,
      })
    );
  };

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={handleUpdateSifakaAnimation}>
          <div className="content-header">
            <Link to="/animation-sifaka" className="btn btn-return text-white">
              Aller à l'animation sifaka
            </Link>
            <h2 className="content-title">Mis à jour animation sifaka</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publier
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {errorUpdate && (
                    <Message variant="alert-danger">{errorUpdate}</Message>
                  )}
                  {loadingUpdate && <Loading />}
                  {loading ? (
                    <Loading />
                  ) : error ? (
                    <Message variant="alert-danger">{error}</Message>
                  ) : (
                    <div className="mb-4">
                      <label
                        htmlFor="animation-sifaka_title"
                        className="form-label"
                      >
                        Modifier animation
                      </label>
                      <input
                        type="text"
                        placeholder="Animation"
                        className="form-control"
                        id="animation-sifaka_title"
                        required
                        value={animation}
                        onChange={(event) => setAnimation(event.target.value)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};
