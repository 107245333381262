import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PRODUCT_CREATE_RESET } from "../../Redux/Constants/ProductConstants";
import { createProduct } from "../../Redux/Actions/ProductActions";
import Message from "../LoadingError/Error";
import Toast from "../LoadingError/Toast";
import Loading from "../LoadingError/Loading";
import { listCategory } from "../../Redux/Actions/CategoryActions";
import { createImage } from "../../Redux/Actions/ImageActions";
import { IMAGE_CREATE_RESET } from "../../Redux/Constants/ImageConatants";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const AddProductMain = () => {
  const URL = process.env.REACT_APP_URI;
  const [name, setName] = useState("");
  const [ref, setRef] = useState("");
  const [price, setPrice] = useState(0);
  const [setImage] = useState();
  const [description, setDescription] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [parentId, setParentId] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  const dispatch = useDispatch();
  const productCreate = useSelector((state) => state.productCreate);
  const { loading, error, product } = productCreate;

  const uploadImage = useSelector((state) => state.imageCreate);
  const { imageUploaded } = uploadImage;

  const categoryLists = useSelector((state) => state.categoryList);
  const category = categoryLists?.categories?.categoryList;

  useEffect(() => {
    dispatch(listCategory());
  }, [dispatch]);

  useEffect(() => {
    if (product) {
      toast.success("Produit ajouté", ToastObjects);
      dispatch({ type: PRODUCT_CREATE_RESET });
      setName("");
      setRef("");
      setPrice(0);
      setDescription("");
      setCountInStock(0);
    }
    if (imageUploaded) {
      dispatch({ type: IMAGE_CREATE_RESET });
      setImage("");
    }
  }, [product, dispatch, imageUploaded, setImage]);

  const handleAddProduct = (event) => {
    event.preventDefault();
    const urlPath = `${URL}/api/download`;
    dispatch(createImage(selectedFile));
    dispatch(
      createProduct(
        name,
        `${urlPath}/${selectedFile?.name}`,
        description,
        price,
        countInStock,
        parentId,
        ref
      )
    );
    // history.push("/produits");
  };

  const listCategorys = category
    ?.map((cat) => {
      return cat?.children;
    })
    .flat();

  // console.log("CAT*****", listCategorys);

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={handleAddProduct}>
          <div className="content-header">
            <Link to="/produits" className="btn btn-return text-white">
              Retour au produit
            </Link>
            <h2 className="content-title">Ajouter produit</h2>
            <div>
              <Link
                to="/produits"
                className="btn btn-primary"
                onClick={handleAddProduct}
              >
                Publier
              </Link>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Nom du produit :
                    </label>
                    <input
                      type="text"
                      placeholder="Nom du produit"
                      className="form-control"
                      id="product_title"
                      required
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_title" className="form-label">
                      Référence de produit :
                    </label>
                    <input
                      type="text"
                      placeholder="Référence de produit"
                      className="form-control"
                      id="product_ref"
                      required
                      value={ref}
                      onChange={(event) => setRef(event.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Prix du produit :
                    </label>
                    <input
                      type="number"
                      placeholder="Prix du produit"
                      className="form-control"
                      id="product_price"
                      required
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Stock disponible :
                    </label>
                    <input
                      type="number"
                      placeholder="Stock disponible"
                      className="form-control"
                      id="product_price"
                      required
                      value={countInStock}
                      onChange={(event) => setCountInStock(event.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Description :</label>
                    <textarea
                      placeholder="Description"
                      className="form-control"
                      rows="7"
                      required
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Categorie :</label>
                    <select
                      value={parentId}
                      onChange={(event) => setParentId(event.target.value)}
                      className="select-categorie"
                      required
                    >
                      <option>Choisir categorie </option>
                      {listCategorys?.map((cat) => (
                        <option key={cat?._id}>{cat?.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Images :</label>
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="Entrer Image URL"
                      value={image}
                      onChange={(event) => setImage(event.target.value)}
                      required
                    /> */}
                    <input
                      type="file"
                      // value={}
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                      className="select-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddProductMain;
