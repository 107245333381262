import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { createFlashSale } from "../../Redux/Actions/FlashSaleActions";
import { createImage } from "../../Redux/Actions/ImageActions";
import { FLASHSALE_CREATE_RESET } from "../../Redux/Constants/FlashSaleConstants";
import { IMAGE_CREATE_RESET } from "../../Redux/Constants/ImageConatants";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const AddFlashSaleMain = () => {
  const URL = process.env.REACT_APP_URI;
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [setImage] = useState("");
  const [description, setDescription] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [selectedFile, setSelectedFile] = useState();

  const dispatch = useDispatch();
  // const flashSaleCreate = useSelector((state) => state.flashSaleCreate);
  // const { loading, error, flashsale } = flashSaleCreate || {};
  const flashsales = useSelector((state) => state.flashsaleList.flashsales);
  const loading = useSelector((state) => state.flashsaleList.loading);
  const error = useSelector((state) => state.flashsaleList.error)

  const uploadImage = useSelector((state) => state.imageCreate);
  const { imageUploaded } = uploadImage;

  useEffect(() => {
    if (flashsales) {
      // toast.success("Vente flash ajouté", ToastObjects);
      dispatch({ type: FLASHSALE_CREATE_RESET });
      setName("");
      setPrice(0);
      setDescription("");
      setCountInStock(0);
    }
    if (imageUploaded) {
      dispatch({ type: IMAGE_CREATE_RESET });
      setImage("");
    }
  }, [flashsales, dispatch, imageUploaded, setImage]);

  const handleAddFlashSale = (event) => {
    event.preventDefault();
    const urlPath = `${URL}/api/download`;
    dispatch(createImage(selectedFile));
    dispatch(
      createFlashSale(
        name,
        `${urlPath}/${selectedFile?.name}`,
        description,
        price,
        countInStock
      )
    );
    // history.push("/venteflashs");
  };

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={handleAddFlashSale}>
          <div className="content-header">
            <Link to="/venteflashs" className="btn btn-return text-white">
              Retour au vente flash
            </Link>
            <h2 className="content-title">Ajouter vente flash</h2>
            <div>
              <Link
                to="/venteflashs"
                className="btn btn-primary"
                onClick={handleAddFlashSale}
              >
                Publier
              </Link>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}
                  <div className="mb-4">
                    <label htmlFor="flashsale_title" className="form-label">
                      Nom du product
                    </label>
                    <input
                      type="text"
                      placeholder="Nom du product"
                      className="form-control"
                      id="flashsale_title"
                      required
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="flashsale_price" className="form-label">
                      Prix
                    </label>
                    <input
                      type="number"
                      placeholder="Prix"
                      className="form-control"
                      id="flashsale_price"
                      required
                      value={price}
                      onChange={(event) => setPrice(event.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="flashsale_price" className="form-label">
                      Stock disponible
                    </label>
                    <input
                      type="number"
                      placeholder="Stock disponible"
                      className="form-control"
                      id="flashsale_price"
                      required
                      value={countInStock}
                      onChange={(event) => setCountInStock(event.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Description</label>
                    <textarea
                      placeholder="Description"
                      className="form-control"
                      rows="7"
                      required
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Images :</label>
                    <input
                      type="file"
                      // value={}
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                      className="select-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddFlashSaleMain;
