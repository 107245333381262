import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import EditflashSaleMain from "../components/flashSales/EditflashSaleMain";

const FlashSaleEditScreen = ({ match }) => {
  const flashsaleId = match.params.id;
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <EditflashSaleMain flashsaleId={flashsaleId} />
      </main>
    </>
  );
};
export default FlashSaleEditScreen;
