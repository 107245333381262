import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import AddFlashSaleMain from "./../components/flashSales/AddFlashSaleMain";

const AddFlashSale = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <AddFlashSaleMain />
      </main>
    </>
  );
};

export default AddFlashSale;