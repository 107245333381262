import axios from "axios";
import {
  CATEGORY_CREATE_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_EDIT_FAIL,
  CATEGORY_EDIT_REQUEST,
  CATEGORY_EDIT_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_SUCCESS,
} from "../Constants/CategoryConstants";
import { logout } from "./UserActions";

export const listCategory = () => async (dispatch) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: CATEGORY_LIST_SUCCESS });

    const { data } = await axios.get(`${URL}/api/category`);
    dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// CREATE CATEGORY
export const createCategory =
  (name, slug, parentId) => async (dispatch, getState) => {
    const URL = process.env.REACT_APP_URI
    // console.log("DATA<<<<<<<<<<<<<<<<<", name, slug, parentId);
    try {
      dispatch({ type: CATEGORY_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      };
      const { data } = await axios.post(
        `${URL}/api/category/`,
        { name, slug, parentId },
        config
      );

      dispatch({ type: CATEGORY_CREATE_SUCCESS, payload: data });
      dispatch(listCategory());
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Non autoriser, invalide token") {
        dispatch(logout());
      }
      dispatch({
        type: CATEGORY_CREATE_FAIL,
        payload: message,
      });
    }
  };

// DELETE CATEGORY
export const deleteCategory = (id) => async (dispatch, getState) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: CATEGORY_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Credentials": "true",
      },
    };
    await axios.delete(`${URL}/api/category/${id}`, config);

    dispatch({ type: CATEGORY_DELETE_SUCCESS });
    dispatch(listCategory());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: CATEGORY_DELETE_FAIL,
      payload: message,
    });
  }
};

// EDIT CATEGORY
export const editCategory = (id) => async (dispatch) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: CATEGORY_EDIT_REQUEST });

    const { data } = await axios.put(`${URL}/api/category/${id}`);
    dispatch({ type: CATEGORY_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: CATEGORY_EDIT_FAIL,
      payload: message,
    });
  }
};
