import axios from "axios";
import {
  SIFAKA_ANIMATION_FAIL,
  SIFAKA_ANIMATION_REQUEST,
  SIFAKA_ANIMATION_SUCCESS,
  SIFAKA_CREATE_ANIMATION_FAIL,
  SIFAKA_CREATE_ANIMATION_REQUEST,
  SIFAKA_CREATE_ANIMATION_SUCCESS,
  SIFAKA_DELETE_ANIMATION_FAIL,
  SIFAKA_DELETE_ANIMATION_REQUEST,
  SIFAKA_DELETE_ANIMATION_SUCCESS,
  SIFAKA_EDIT_ANIMATION_FAIL,
  SIFAKA_EDIT_ANIMATION_REQUEST,
  SIFAKA_EDIT_ANIMATION_SUCCESS,
  SIFAKA_UPDATE_ANIMATION_FAIL,
  SIFAKA_UPDATE_ANIMATION_REQUEST,
  SIFAKA_UPDATE_ANIMATION_SUCCESS,
} from "./../Constants/AnimationSifakaConstants";
import { logout } from "./UserActions";

// CREATE
export const createAnimationSifaka =
  (animation) => async (dispatch, getState) => {
    const URL = process.env.REACT_APP_URI;
    try {
      dispatch({ type: SIFAKA_CREATE_ANIMATION_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${URL}/api/animationSifaka`,
        { animation },
        config
      );
      // console.log("DATA", { animation });

      dispatch({ type: SIFAKA_CREATE_ANIMATION_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Non autoriser, invalide token") {
        dispatch(logout());
      }
      dispatch({
        type: SIFAKA_CREATE_ANIMATION_FAIL,
        payload: message,
      });
    }
  };

//Get
export const listAnimationSifaka = () => async (dispatch, getState) => {
  const URL = process.env.REACT_APP_URI;
  try {
    dispatch({ type: SIFAKA_ANIMATION_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    // console.log("*****BOOM");

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${URL}/api/animationSifaka/animation-sifaka`,
      config
    );

    dispatch({ type: SIFAKA_ANIMATION_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: SIFAKA_ANIMATION_FAIL,
      payload: message,
    });
  }
};

// DELETE
export const deleteSifakaAnimation = (id) => async (dispatch, getState) => {
  const URL = process.env.REACT_APP_URI;
  try {
    dispatch({ type: SIFAKA_DELETE_ANIMATION_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`${URL}/api/animationSifaka/${id}`, config);

    dispatch({ type: SIFAKA_DELETE_ANIMATION_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: SIFAKA_DELETE_ANIMATION_FAIL,
      payload: message,
    });
  }
};

// EDIT
export const editSifakaAnimation = (id) => async (dispatch) => {
  const URL = process.env.REACT_APP_URI;
  try {
    dispatch({ type: SIFAKA_EDIT_ANIMATION_REQUEST });

    const { data } = await axios.get(`${URL}/api/animationSifaka/${id}`);
    dispatch({ type: SIFAKA_EDIT_ANIMATION_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: SIFAKA_EDIT_ANIMATION_FAIL,
      payload: message,
    });
  }
};

// UPDATE
export const updateSifakaAnimation =
  (animationSifaka) => async (dispatch, getState) => {
    const URL = process.env.REACT_APP_URI;
    try {
      dispatch({ type: SIFAKA_UPDATE_ANIMATION_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${URL}/api/animationSifaka/${animationSifaka._id}`,
        animationSifaka,
        config
      );

      dispatch({ type: SIFAKA_UPDATE_ANIMATION_SUCCESS, payload: data });
      dispatch({ type: SIFAKA_EDIT_ANIMATION_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Non autoriser, invalide token") {
        dispatch(logout());
      }
      dispatch({
        type: SIFAKA_UPDATE_ANIMATION_FAIL,
        payload: message,
      });
    }
  };
