import React, { useEffect } from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/productScreen";
import CategoriesScreen from "./screens/CategoriesScreen";
import OrderScreen from "./screens/OrderScreen";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import AddProduct from "./screens/AddProduct";
import Login from "./screens/LoginScreen";
import UsersScreen from "./screens/UsersScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import NotFound from "./screens/NotFound";
import PrivateRouter from "./PrivateRouter";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "./Redux/Actions/ProductActions";
import { listOrders } from "./Redux/Actions/OrderActions";
import FlashSaleScreen from "./screens/FlashSaleScreen";
import { listFlashSale } from "./Redux/Actions/FlashSaleActions";
import FlashSaleEditScreen from "./screens/FlashSaleEditScreen";
import AddFlashSale from "./screens/AddFlashSale";
import AnimationFlashSaleScreen from "./screens/AnimationFlashSaleScreen";
import { AddAnimationFlashSale } from "./components/AnimationVenteFlash/AddAnimationFlashSale";
import { AddAnimationSifaka } from "./components/AnimationSifaka/AddAnimationSifaka";
import AnimationSifakaScreen from "./screens/AnimationSifakaScreen";
import EditAnimationFlashSaleScreen from "./screens/AnimationFlashSaleEditScreen";
import EditAnimationSifakaScreen from "./screens/AnimationSifakaEditScreen";

function App() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo?.isAdmin && userInfo?.isAdmin) {
      dispatch(listProducts);
      dispatch(listOrders);
      dispatch(listFlashSale);
    }
  }, [dispatch, userInfo]);

  return (
    <>
      <Router>
        <Switch>
          <PrivateRouter path="/" component={HomeScreen} exact />
          <PrivateRouter path="/parentId/:parentId" component={ProductScreen} />
          <PrivateRouter
            path="/produits/page/:pageNumber"
            component={ProductScreen}
          />
          <PrivateRouter path="/produits" component={ProductScreen} />
          <PrivateRouter path="/categories" component={CategoriesScreen} />
          <PrivateRouter path="/commandes" component={OrderScreen} />
          <PrivateRouter path="/commande/:id" component={OrderDetailScreen} />
          <PrivateRouter path="/ajout-produit" component={AddProduct} />
          <PrivateRouter path="/venteflashs" component={FlashSaleScreen} />
          <PrivateRouter
            path="/animation-vente-flash"
            component={AnimationFlashSaleScreen}
          />
          <PrivateRouter
            path="/animation-sifaka"
            component={AnimationSifakaScreen}
          />
          <PrivateRouter path="/ajout-vente-flash" component={AddFlashSale} />
          <PrivateRouter
            path="/ajout-animation-vente-flash"
            component={AddAnimationFlashSale}
          />
          <PrivateRouter
            path="/ajout-animation-sifaka"
            component={AddAnimationSifaka}
          />
          <PrivateRouter path="/utilisateurs" component={UsersScreen} />
          <PrivateRouter
            path="/product/:id/edit"
            component={ProductEditScreen}
          />
          <PrivateRouter
            path="/venteflash/:id/edit"
            component={FlashSaleEditScreen}
          />
          <PrivateRouter
            path="/animation-venteflash/:id/edit"
            component={EditAnimationFlashSaleScreen}
          />
          <PrivateRouter
            path="/animation_sifaka/:id/edit"
            component={EditAnimationSifakaScreen}
          />
          <Route path="/login" component={Login} />
          <PrivateRouter path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
