import {
  IMAGE_CREATE_FAIL,
  IMAGE_CREATE_REQUEST,
  IMAGE_CREATE_RESET,
  IMAGE_CREATE_SUCCESS,
  IMAGE_DELETE_FAIL,
  IMAGE_DELETE_REQUEST,
  IMAGE_DELETE_SUCCESS,
  IMAGE_EDIT_FAIL,
  IMAGE_EDIT_REQUEST,
  IMAGE_EDIT_SUCCESS,
  IMAGE_LIST_FAIL,
  IMAGE_LIST_REQUEST,
  IMAGE_LIST_SUCCESS,
} from "../Constants/ImageConatants";

// IMAGE LIST
export const imageListReducer = (state = { images: [] }, action) => {
  switch (action.type) {
    case IMAGE_LIST_REQUEST:
      return { loading: true, images: [] };
    case IMAGE_LIST_SUCCESS:
      return {
        loading: false,
        images: action.payload,
      };
    case IMAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPLOAD IMAGE
export const imageCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_CREATE_REQUEST:
      return { loading: true };
    case IMAGE_CREATE_SUCCESS:
      return { loading: false, success: true, image: action.payload };
    case IMAGE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case IMAGE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// DELETE IMAGE
export const imageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_DELETE_REQUEST:
      return { loading: true };
    case IMAGE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case IMAGE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// EDIT IMAGE
export const imageEditReducer = (state = { image: {} }, action) => {
  switch (action.type) {
    case IMAGE_EDIT_REQUEST:
      return { ...state, loading: true };
    case IMAGE_EDIT_SUCCESS:
      return { loading: false, success: true, image: action.payload };
    case IMAGE_EDIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
