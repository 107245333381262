export const SIFAKA_ANIMATION_REQUEST = "SIFAKA_ANIMATION_REQUEST";
export const SIFAKA_ANIMATION_SUCCESS = "SIFAKA_ANIMATION_SUCCESS";
export const SIFAKA_ANIMATION_FAIL = "SIFAKA_ANIMATION_FAIL";

export const SIFAKA_CREATE_ANIMATION_REQUEST =
  "SIFAKA_CREATE_ANIMATION_REQUEST";
export const SIFAKA_CREATE_ANIMATION_SUCCESS =
  "SIFAKA_CREATE_ANIMATION_SUCCESS";
export const SIFAKA_CREATE_ANIMATION_FAIL = "SIFAKA_CREATE_ANIMATION_FAIL";
export const SIFAKA_CREATE_ANIMATION_RESET = "SIFAKA_CREATE_ANIMATION_RESET";

export const SIFAKA_EDIT_ANIMATION_REQUEST = "SIFAKA_EDIT_ANIMATION_REQUEST";
export const SIFAKA_EDIT_ANIMATION_SUCCESS = "SIFAKA_EDIT_ANIMATION_SUCCESS";
export const SIFAKA_EDIT_ANIMATION_FAIL = "SIFAKA_EDIT_ANIMATION_FAIL";

export const SIFAKA_DELETE_ANIMATION_REQUEST =
  "SIFAKA_DELETE_ANIMATION_REQUEST";
export const SIFAKA_DELETE_ANIMATION_SUCCESS =
  "SIFAKA_DELETE_ANIMATION_SUCCESS";
export const SIFAKA_DELETE_ANIMATION_FAIL = "SIFAKA_DELETE_ANIMATION_FAIL";

export const SIFAKA_UPDATE_ANIMATION_REQUEST =
  "SIFAKA_UPDATE_ANIMATION_REQUEST";
export const SIFAKA_UPDATE_ANIMATION_SUCCESS =
  "SIFAKA_UPDATE_ANIMATION_SUCCESS";
export const SIFAKA_UPDATE_ANIMATION_FAIL = "SIFAKA_UPDATE_ANIMATION_FAIL";
export const SIFAKA_UPDATE_ANIMATION_RESET = "SIFAKA_UPDATE_ANIMATION_RESET";
