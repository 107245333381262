import React, { useEffect, useState } from "react";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";
import { Link } from "react-router-dom";
import Sidebar from "../sidebar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SIFAKA_CREATE_ANIMATION_RESET } from "../../Redux/Constants/AnimationSifakaConstants";
import { createAnimationSifaka } from "../../Redux/Actions/AnimationSifakaAction";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

export const AddAnimationSifaka = () => {
  const [animation, setAnimation] = useState("");

  const dispatch = useDispatch();
  const animationListSifaka = useSelector(
    (state) => state.sifakaAnimationCreate
  );
  const { loading, error, animationSifaka } = animationListSifaka || {};

  useEffect(() => {
    if (animationSifaka) {
      toast.success("Animation sifaka ajouté", ToastObjects);
      dispatch({ type: SIFAKA_CREATE_ANIMATION_RESET });
      setAnimation("");
    }
  }, [animationSifaka, dispatch]);

  const handleAddAnimationSifaka = (event) => {
    event.preventDefault();
    dispatch(createAnimationSifaka(animation));
  };

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Toast />
        <section className="content-main" style={{ maxWidth: "1200px" }}>
          <form onSubmit={handleAddAnimationSifaka}>
            <div className="content-header">
              <Link
                to="/animation-sifaka"
                className="btn btn-return text-white"
              >
                Retour au animation sifaka
              </Link>
              <h2 className="content-title">Ajouter animation sifaka</h2>
              <div>
                <Link
                  to="/animation-sifaka"
                  className="btn btn-primary"
                  onClick={handleAddAnimationSifaka}
                >
                  Publier
                </Link>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-xl-8 col-lg-8">
                <div className="card mb-4 shadow-sm">
                  <div className="card-body">
                    {error && <Message variant="alert-danger">{error}</Message>}
                    {loading && <Loading />}
                    <div className="mb-4">
                      <label
                        htmlFor="animation-sifaka_title"
                        className="form-label"
                      >
                        Animation
                      </label>
                      <input
                        type="text"
                        placeholder="Animation"
                        className="form-control"
                        id="animation-sifaka_title"
                        required
                        value={animation}
                        onChange={(event) => setAnimation(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </main>
    </>
  );
};
