import React from "react";
import { Link } from "react-router-dom";

const OrderDetailProducts = (props) => {
  const { loading, order } = props;

  if (!loading) {
    //Calcul prix
    // const addDecimals = (num) => {
    //   return (Math.round(num * 100) / 100).toFixed(2);
    // };

    // order.orderItems = addDecimals(
    //   order?.itemsPrice .reduce((acc, item) => acc + item?.price * item?.qty, 0)
    // );
  }

  // console.log("Commande Liste=========", order);

  return (
    <table className="table border table-lg">
      <thead>
        <tr>
          <th style={{ width: "40%" }}>Produit</th>
          <th style={{ width: "20%" }}>Prix</th>
          <th style={{ width: "20%" }}>Quantité(s)</th>
          <th style={{ width: "20%" }} className="text-end">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {order?.orderItems.map((item, index) => (
          <tr key={index}>
            <td>
              <Link className="itemside" to="#">
                <div className="left">
                  <img
                    src={item?.image}
                    alt={item?.name}
                    style={{ width: "40px", height: "40px" }}
                    className="img-xs"
                  />
                </div>
                <div className="info">{item?.name}</div>
              </Link>
            </td>
            <td>{item?.price} Ar </td>
            <td>{item?.qty}</td>
            <td className="text-end"> {item?.qty * item?.price}</td>
          </tr>
        ))}

        <tr>
          <td colSpan="4">
            <article className="float-end">
              <dl className="dlist">
                <dt>Subtotal:</dt> <dd>{order?.itemsPrice} Ar</dd>
              </dl>
              <dl className="dlist">
                <dt>Frais d'envoie:</dt> <dd>{order?.shippingPrice} Ar</dd>
              </dl>
              <dl className="dlist">
                <dt>Grand total:</dt>
                <dd>
                  <b className="h5">{order?.totalPrice} Ar</b>
                </dd>
              </dl>
              <dl className="dlist">
                <dt className="text-muted">Statut:</dt>
                <dd>
                  {order?.isPai ? (
                    <span className="badge rounded-pill alert alert-success text-success">
                      Paié
                    </span>
                  ) : (
                    <span className="badge rounded-pill alert alert-danger text-danger">
                      Non paié
                    </span>
                  )}
                </dd>
              </dl>
            </article>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderDetailProducts;
