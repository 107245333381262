import {
  FLASHSALE_ANIMATION_FAIL,
  FLASHSALE_ANIMATION_REQUEST,
  FLASHSALE_ANIMATION_SUCCESS,
  FLASHSALE_CREATE_ANIMATION_FAIL,
  FLASHSALE_CREATE_ANIMATION_REQUEST,
  FLASHSALE_CREATE_ANIMATION_RESET,
  FLASHSALE_CREATE_ANIMATION_SUCCESS,
  FLASHSALE_DELETE_ANIMATION_FAIL,
  FLASHSALE_DELETE_ANIMATION_REQUEST,
  FLASHSALE_DELETE_ANIMATION_SUCCESS,
  FLASHSALE_EDIT_ANIMATION_FAIL,
  FLASHSALE_EDIT_ANIMATION_REQUEST,
  FLASHSALE_EDIT_ANIMATION_SUCCESS,
  FLASHSALE_UPDATE_ANIMATION_FAIL,
  FLASHSALE_UPDATE_ANIMATION_REQUEST,
  FLASHSALE_UPDATE_ANIMATION_RESET,
  FLASHSALE_UPDATE_ANIMATION_SUCCESS,
} from "../Constants/AnimationFlashSaleConstants";

//Get
export const animationFlashSaleListReducer = (
  state = { animationFlashSale: [] },
  action
) => {
  switch (action.type) {
    case FLASHSALE_ANIMATION_REQUEST:
      return { loading: true, animationFlashSale: [] };
    case FLASHSALE_ANIMATION_SUCCESS:
      return { loading: false, animationFlashSale: action.payload };
    case FLASHSALE_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE
export const animatioFlashSaleCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FLASHSALE_CREATE_ANIMATION_REQUEST:
      return { loading: true };
    case FLASHSALE_CREATE_ANIMATION_SUCCESS:
      return {
        loading: false,
        success: true,
        animationFlashSale: action.payload,
      };
    case FLASHSALE_CREATE_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    case FLASHSALE_CREATE_ANIMATION_RESET:
      return {};
    default:
      return state;
  }
};

// EDIT
export const animationFlashSaleEditReducer = (
  state = { animationFlashSale: {} },
  action
) => {
  switch (action.type) {
    case FLASHSALE_EDIT_ANIMATION_REQUEST:
      return { ...state, loading: true };
    case FLASHSALE_EDIT_ANIMATION_SUCCESS:
      return {
        loading: false,
        success: true,
        animationFlashSale: action.payload,
      };
    case FLASHSALE_EDIT_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// DELETE
export const animationFlashSaleDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FLASHSALE_DELETE_ANIMATION_REQUEST:
      return { loading: true };
    case FLASHSALE_DELETE_ANIMATION_SUCCESS:
      return { loading: false, success: true };
    case FLASHSALE_DELETE_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// UPDATE
export const flashSaleUpdateAnimationReducer = (
  state = { animationFlashSale: {} },
  action
) => {
  switch (action.type) {
    case FLASHSALE_UPDATE_ANIMATION_REQUEST:
      return { loading: true };
    case FLASHSALE_UPDATE_ANIMATION_SUCCESS:
      return {
        loading: false,
        success: true,
        animationFlashSale: action.payload,
      };
    case FLASHSALE_UPDATE_ANIMATION_FAIL:
      return { loading: false, error: action.payload };
    case FLASHSALE_UPDATE_ANIMATION_RESET:
      return { flashsale: {} };
    default:
      return state;
  }
};
