import axios from "axios";
import {
  IMAGE_CREATE_FAIL,
  IMAGE_CREATE_REQUEST,
  IMAGE_CREATE_SUCCESS,
  IMAGE_DELETE_FAIL,
  IMAGE_DELETE_REQUEST,
  IMAGE_DELETE_SUCCESS,
  IMAGE_EDIT_FAIL,
  IMAGE_EDIT_REQUEST,
  IMAGE_EDIT_SUCCESS,
  IMAGE_LIST_FAIL,
  IMAGE_LIST_REQUEST,
  IMAGE_LIST_SUCCESS,
} from "../Constants/ImageConatants";
import { logout } from "./UserActions";

// Get Image
export const listImage = () => async (dispatch) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: IMAGE_LIST_REQUEST });

    const { data } = await axios.get(`${URL}/api/upload`);
    dispatch({ type: IMAGE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: IMAGE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// UPLOAD IMAGE
export const createImage = (selectedFile) => async (dispatch, getState) => {
  const URL = process.env.REACT_APP_URI
  try {
    const formData = new FormData();
    formData.append("file", selectedFile);

    // console.log("DATA<<<<<<<<<<<<<<<<<", selectedFile);

    dispatch({ type: IMAGE_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Credentials": "true",
      },
    };
    const { data } = await axios.post(
      `${URL}/api/upload`,
      formData,
      config
    );

    dispatch({ type: IMAGE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: IMAGE_CREATE_FAIL,
      payload: message,
    });
  }
};

// DELETE IMAGE
export const deleteImage = (id) => async (dispatch, getState) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: IMAGE_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Credentials": "true",
      },
    };
    await axios.delete(`${URL}/api/upload/${id}`, config);

    dispatch({ type: IMAGE_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: IMAGE_DELETE_FAIL,
      payload: message,
    });
  }
};

// EDIT CATEGORY
export const editImage = (id) => async (dispatch) => {
  const URL = process.env.REACT_APP_URI
  try {
    dispatch({ type: IMAGE_EDIT_REQUEST });

    const { data } = await axios.put(`${URL}/api/upload/${id}`);
    dispatch({ type: IMAGE_EDIT_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Non autoriser, invalide token") {
      dispatch(logout());
    }
    dispatch({
      type: IMAGE_EDIT_FAIL,
      payload: message,
    });
  }
};
